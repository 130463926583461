@font-face {
  font-family: 'Sen-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Sen-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Sen-Bold';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/Sen-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Sen-ExtraBold';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/Sen-ExtraBold.ttf') format('truetype');
}

#root {
  height: 100%;
}

*:focus {
  outline: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
